:root{
    --green-700: #141B4D;
    --green-400: #1B8AFD;
    --green-300: #004EA8;
    --green-50: #90A5DB;


    --lime-500: #90A5DB;

    --yellow-700: #141B4D;
    --yellow-300: #BFB8AF;
    --yellow-50: #f9f2d5;
}

html{
    font-family: 'Plus Jakarta Sans' !important;
}

select, select option {
    font-family: 'Plus Jakarta Sans' !important;
}